import Vue from 'vue'
import VueRouter from 'vue-router'
// const Vue = require('vue')
// const VueRouter = require('vue-router')

import store from '../vuex/store'

//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入

import AxiosPlugin from '../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

// import wxUtils from '../common/wxUtils.js'
// Vue.use(wxUtils)

import {
	MessageBox,
} from 'element-ui';

Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(VueRouter)

const routes = [{
		path: '*', //其他页面，强制跳转到找不到页面
		redirect: '/loginFrame/NotFound'
	},
	{
		path: '/',
		// name: 'auth',
		// component: auth,
		redirect: '/loginFrame/login'
	},

	{
		path: '/loginFrame',
		name: 'loginFrame',
		component: () => import('@/components/Login/loginFrame'),
		children: [{
				path: 'NotFound',
				name: 'NotFound',
				component: () => import('@/components/NotFound/NotFound'),
			}, {
				path: 'login',
				name: 'login',
				component: () => import('@/components/Login/login'),
			},
			{
				path: 'register',
				name: 'register',
				component: () => import('@/components/Login/register'),
			},
			{
				path: 'registerSuccess',
				name: 'registerSuccess',
				component: () => import('@/components/Login/registerSuccess'),
			},
			{
				path: 'forgotPassword',
				name: 'forgotPassword',
				component: () => import('@/components/Login/forgotPassword'),
			},
			{
				path: 'auth',
				name: 'auth',
				component: () => import('@/components/Login/auth'),
			},
			// {
			// 	path: 'helpDoc',
			// 	name: 'helpDoc',
			// 	component: helpDoc,
			// },
			{
				path: 'amazonHelpDoc',
				name: 'amazonHelpDoc',
				component: () => import('@/components/Login/help/amazonHelpDoc'),
			},
			// {
			// 	path: 'sitemap',
			// 	name: 'sitemap',
			// 	component: sitemap,
			// },

			// {
			// 	path: 'AmazonAuth',
			// 	name: 'AmazonAuth',
			// 	component: AmazonAuth,
			// },
			// {
			// 	path: 'AmazonAuthList',
			// 	name: 'AmazonAuthList',
			// 	component: AmazonAuthList,
			// },
			{
				path: 'NoticeHelpList',
				name: 'NoticeHelpList',
				component: () => import('@/components/StorageCenter/NoticeMana/NoticeHelpList'),
				meta: {
					isMenu: false
				}
			},
		]
	},

	{
		path: '/AmazonFrame',
		name: 'AmazonFrame',
		component: () => import('@/components/Amazon/AmazonFrame'),
		meta: {
			isWh: true
		},
		children: [{
				path: 'AmazonAuth',
				name: 'AmazonAuth',
				component: () => import('@/components/Amazon/AmazonAuth'),
			},
			{
				path: 'AmazonAuthList',
				name: 'AmazonAuthList',
				component: () => import('@/components/Amazon/AmazonAuthList'),
			},

		]
	},

	//授权页面
	{
		path: '/AuthFrame',
		name: 'AuthFrame',
		component: () => import('@/components/Auth/AuthFrame'),
		children: [{
			path: 'UpsOauth',
			name: 'UpsOauth',
			component: () => import('@/components/Auth/UpsOauth'),
		}, {
			path: 'UpsOauthBack',
			name: 'UpsOauthBack',
			component: () => import('@/components/Auth/UpsOauthBack'),
		}, {
			path: 'UspsOauth',
			name: 'UspsOauth',
			component: () => import('@/components/Auth/UspsOauth'),
		}, {
			path: 'TiktokAuth',
			name: 'TiktokAuth',
			component: () => import('@/components/Auth/TiktokAuth'),
		}, {
			path: 'TiktokAuthBack',
			name: 'TiktokAuthBack',
			component: () => import('@/components/Auth/TiktokAuthBack'),
		}, {
			path: 'TemuAuth',
			name: 'TemuAuth',
			component: () => import('@/components/Auth/TemuAuth'),
		}, {
			path: 'SheinAuth',
			name: 'SheinAuth',
			component: () => import('@/components/Auth/SheinAuth'),
		}],
	},


	{
		path: '/StorageCenterFrame',
		name: 'StorageCenterFrame',
		component: () => import('@/components/StorageCenter/StorageCenterFrame'),
		meta: {
			isWh: true
		},

		children: [{
				path: 'Home',
				name: 'Home',
				component: () => import('@/components/StorageCenter/Home/Home'),
			},
			{
				path: 'InWarehouse',
				name: 'InWarehouse',
				component: () => import('@/components/StorageCenter/clp/InWarehouse'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WarehouseFee',
				name: 'WarehouseFee',
				component: () => import('@/components/StorageCenter/statement/WarehouseFee'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WarehouseFeeCollect',
				name: 'WarehouseFeeCollect',
				component: () => import('@/components/StorageCenter/statement/WarehouseFeeCollect'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'WarehouseShipments',
				name: 'WarehouseShipments',
				component: () => import('@/components/StorageCenter/shipments/WarehouseShipments'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportInWh',
				name: 'TransportInWh',
				component: () => import('@/components/StorageCenter/Transport/TransportInWh'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportInWhEdit',
				name: 'TransportInWhEdit',
				component: () => import('@/components/StorageCenter/Transport/TransportInWhEdit'),
			},
			// {
			// 	path: 'TransportInWhDetList',
			// 	name: 'TransportInWhDetList',
			// 	component: TransportInWhDetList
			// },
			{
				path: 'TransportInWhByToOut',
				name: 'TransportInWhByToOut',
				component: () => import('@/components/StorageCenter/Transport/TransportInWhByToOut'),
			},
			{
				path: 'TransportOutWh',
				name: 'TransportOutWh',
				component: () => import('@/components/StorageCenter/Transport/TransportOutWh'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportOutWhEdit',
				name: 'TransportOutWhEdit',
				component: () => import('@/components/StorageCenter/Transport/TransportOutWhEdit'),
			},
			{
				path: 'TransportOutWhDetList',
				name: 'TransportOutWhDetList',
				component: () => import('@/components/StorageCenter/Transport/TransportOutWhDetList'),
			},
			{
				path: 'TransportInventories',
				name: 'TransportInventories',
				component: () => import('@/components/StorageCenter/Transport/TransportInventories'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'TransportPlanOutWh',
				name: 'TransportPlanOutWh',
				component: () => import('@/components/StorageCenter/Transport/TransportPlanOutWh'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportPlanOutWhEdit',
				name: 'TransportPlanOutWhEdit',
				component: () => import('@/components/StorageCenter/Transport/TransportPlanOutWhEdit'),
			},
			{
				path: 'TransportFastList',
				name: 'TransportFastList',
				component: () => import('@/components/StorageCenter/Transport/TransportFastList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'TransportFastEdit',
				name: 'TransportFastEdit',
				component: () => import('@/components/StorageCenter/Transport/TransportFast/TransportFastEdit'),
			},


			{
				path: 'WarehouseExchangeReturn',
				name: 'WarehouseExchangeReturn',
				component: () => import('@/components/StorageCenter/shipments/WarehouseExchangeReturn'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'UnclaimedGoodsListAll',
				name: 'UnclaimedGoodsListAll',
				component: () => import('@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsListAll'),
				meta: {
					isMenu: true
				}
			},



			// {
			// 	path: 'WarehouseSku',
			// 	name: 'WarehouseSku',
			// 	component: WarehouseSku
			// },
			{
				path: 'WSkuInfo',
				name: 'WSkuInfo',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuInfo'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuInventories',
				name: 'WSkuInventories',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuInventories'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WhStockList',
				name: 'WhStockList',
				component: () => import('@/components/StorageCenter/WSkuProduct/WhStockList'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'WSkuRecord',
				name: 'WSkuRecord',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuRecord'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuSplit',
				name: 'WSkuSplit',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuSplit'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuAddSuccessPage',
				name: 'WSkuAddSuccessPage',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuAddSuccessPage'),
			},


			{
				path: 'WarehouseDropShipping',
				name: 'WarehouseDropShipping',
				component: () => import('@/components/StorageCenter/shipments/WarehouseDropShipping'),
				meta: {
					isMenu: true
				}
			},
			// {
			// 	path: 'PriterOrderDetList',
			// 	name: 'PriterOrderDetList',
			// 	component: PriterOrderDetList
			// },

			{
				path: 'workOrderList',
				name: 'workOrderList',
				component: () => import('@/components/StorageCenter/workOrder/workOrderList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'createWorkOrder',
				name: 'createWorkOrder',
				component: () => import('@/components/StorageCenter/workOrder/createWorkOrder'),
			},
			{
				path: 'createOrder',
				name: 'createOrder',
				component: () => import('@/components/StorageCenter/workOrder/createOrder'),
			},

			{
				path: 'ExceptionalRecord',
				name: 'ExceptionalRecord',
				component: () => import('@/components/StorageCenter/ExceptionalThing/ExceptionalRecord'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'InventoryStatistics',
				name: 'InventoryStatistics',
				component: () => import('@/components/StorageCenter/statement/InventoryStatistics'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'InventoryStatisticsSkuGroup',
				name: 'InventoryStatisticsSkuGroup',
				component: () => import('@/components/StorageCenter/statement/InventoryStatisticsSkuGroup'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'CargoVolumeStatisticsByDay',
				name: 'CargoVolumeStatisticsByDay',
				component: () => import('@/components/StorageCenter/statement/CargoVolumeStatisticsByDay'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'outBill',
				name: 'outBill',
				component: () => import('@/components/StorageCenter/statement/outBill'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'OrderStatisticsByMon',
				name: 'OrderStatisticsByMon',
				component: () => import('@/components/StorageCenter/statement/OrderStatisticsByMon'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentUser',
				name: 'agentUser',
				component: () => import('@/components/StorageCenter/agent/agentUser'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentWarehouseFee',
				name: 'agentWarehouseFee',
				component: () => import('@/components/StorageCenter/agent/agentWarehouseFee'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'agentWarehouseFeeCollect',
				name: 'agentWarehouseFeeCollect',
				component: () => import('@/components/StorageCenter/agent/agentWarehouseFeeCollect'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'amazonUserList',
				name: 'amazonUserList',
				component: () => import('@/components/StorageCenter/amazon/amazonUserList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'amazonOrder',
				name: 'amazonOrder',
				component: () => import('@/components/StorageCenter/amazon/amazonOrder'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'RechargeRecord',
				name: 'RechargeRecord',
				component: () => import('@/components/StorageCenter/account/RechargeRecord'),
			},
			{
				path: 'expenseRecord',
				name: 'expenseRecord',
				component: () => import('@/components/StorageCenter/account/expenseRecord'),
			},
			{
				path: 'BillManage',
				name: 'BillManage',
				component: () => import('@/components/StorageCenter/FinanceMana/BillManage'),
			},
			{
				path: 'BillHome',
				name: 'BillHome',
				component: () => import('@/components/StorageCenter/FinanceMana/BillHome/BillHome'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'accountInfo',
				name: 'accountInfo',
				component: () => import('@/components/StorageCenter/account/accountInfo'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdOrder',
				name: 'ThirdOrder',
				component: () => import('@/components/StorageCenter/Thirdparty/ThirdOrder'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdYiCangApiConList',
				name: 'ThirdYiCangApiConList',
				component: () => import('@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/ThirdYiCangApiConList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'YiCangInventorySync',
				name: 'YiCangInventorySync',
				component: () => import('@/components/StorageCenter/Thirdparty/ThirdYiCangApiConfig/YiCangInventorySync'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'ExpressSheetList',
				name: 'ExpressSheetList',
				component: () => import('@/components/StorageCenter/ExpressSheet/ExpressSheetList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ExpressSheetDetList',
				name: 'ExpressSheetDetList',
				component: () => import('@/components/StorageCenter/ExpressSheet/ExpressSheetDetList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ExpressTrunckNotOnlineList',
				name: 'ExpressTrunckNotOnlineList',
				component: () => import('@/components/StorageCenter/ExpressSheet/ExpressTrunckNotOnlineList'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'LgsTrackQuery',
				name: 'LgsTrackQuery',
				component: () => import('@/components/StorageCenter/ExpressSheet/LgsTrackQuery'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdWmsConfigList',
				name: 'ThirdWmsConfigList',
				component: () => import('@/components/StorageCenter/ThirdWmsConfig/ThirdWmsConfigList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdWmsAsyncInfoList',
				name: 'ThirdWmsAsyncInfoList',
				component: () => import('@/components/StorageCenter/ThirdWmsConfig/ThirdWmsAsyncInfoList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'ThirdApplicationList',
				name: 'ThirdApplicationList',
				component: () => import('@/components/StorageCenter/ThirdApplicationAuth/ThirdApplicationList'),
				// meta: {
				// 	isMenu: true //加入白名单，回调后需要跳转到此列表
				// }
			},
			{
				path: 'ThirdAppOrder',
				name: 'ThirdAppOrder',
				component: () => import('@/components/StorageCenter/ThirdApplicationAuth/ThirdAppOrder'),
				meta: {
					isMenu: true
				}
			},
			// {
			// 	path: 'ThirdAppOrderLabels',
			// 	name: 'ThirdAppOrderLabels',
			// 	component: ThirdAppOrderLabels,

			// },

			{
				path: 'QARecList',
				name: 'QARecList',
				component: () => import('@/components/StorageCenter/QA/QARecList'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'QARecAdd',
				name: 'QARecAdd',
				component: () => import('@/components/StorageCenter/QA/QARecAdd'),
			},
			{
				path: 'QARecProgress',
				name: 'QARecProgress',
				component: () => import('@/components/StorageCenter/QA/QARecProgress'),
			},
			{
				path: 'ValueAddServiceList',
				name: 'ValueAddServiceList',
				component: () => import('@/components/StorageCenter/QA/ValueAddServiceList'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'userMenusConfig',
				name: 'userMenusConfig',
				component: () => import('@/components/StorageCenter/userMenus/userMenusConfig'),
				meta: {
					isMenu: true
				}
			},

			{
				path: 'subUserAccount',
				name: 'subUserAccount',
				component: () => import('@/components/StorageCenter/userMenus/subUserAccount'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'NoticeList',
				name: 'NoticeList',
				component: () => import('@/components/StorageCenter/NoticeMana/NoticeList'),
				meta: {
					isMenu: false
				}
			},
			{
				path: 'NoticeDet',
				name: 'NoticeDet',
				component: () => import('@/components/StorageCenter/NoticeMana/NoticeDet'),
				meta: {
					isMenu: false
				}
			},
			
			{
				path: 'WSkuRelation',
				name: 'WSkuRelation',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuRelation'),
				meta: {
					isMenu: true
				}
			},
			{
				path: 'WSkuRelationAdd',
				name: 'WSkuRelationAdd',
				component: () => import('@/components/StorageCenter/WSkuProduct/WSkuRelation/WSkuRelationAdd'),
				meta: {
					isMenu: false
				}
			},
		]
	},
	{
		path: '/PrinterFrame',
		name: 'PrinterFrame',
		component: () => import('@/printer/PrinterFrame'),
		// meta: {
		// 	isMenu: false
		// },
		children: [{
			path: 'whPriterTransportPlanOutList',
			name: 'whPriterTransportPlanOutList',
			component: () => import('@/printer/whPriterTransportPlanOutList'),
		}]
	},
];

const router = new VueRouter({
	mode: 'history',
	routes: routes,
	//滚动行为
	scrollBehavior(to, from, savedPosition) {
		// return 期望滚动到哪个的位置
		if (savedPosition) { //浏览器前进后退
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});


// 加载进度条，简单配置
NProgress.inc(0.2)
NProgress.configure({
	easing: 'ease',
	speed: 500,
	showSpinner: false
})

const whiteList = [];
//需要鉴权的，且非菜单的添加到白名单
routes.forEach(item => {
	if (item.meta && item.meta.isWh) { //需要鉴权的
		if (item.children) {
			item.children.forEach(item2 => {
				//存在 meta，并且 isMenu为false，加入白名单
				// isMenu: true 为菜单时，可以不写meta
				// if(!!item2.meta){//有meta
				// 	if(!item2.meta.isMenu){//isMenu 为 fasle
				// 		whiteList.push(item2.name); //非菜单项
				// 	}
				// } 
				// else {
				// 	whiteList.push(item2.name);
				// }
				// 无 meta或者 isMenu为 false，都为白名单
				// isMenu 为 true 需要鉴权的菜单
				if (!(item2.meta && item2.meta.isMenu)) { //非菜单项
					whiteList.push(item2.name); //非菜单项
				}
			})
		}
	}
})

router.beforeEach((to, from, next) => {
	// console.log('beforeEach', to, from);
	// 加载进度条
	NProgress.start();
	//更新logo显示
	store.dispatch('UPDATE_SHOWCONF');
	console.log('sysShow', store.state.sysShow);

	let userInfo = store.getters.getUserInfo;

	let showTips = store.getters.getLangMsg;

	let MenusInfoKey = store.getters.getMenusInfoKey;

	console.log('whiteList', whiteList);
	console.log('MenusInfoKey', MenusInfoKey);
	//菜单权限
	// if (to.name != 'Login' && to.name != 'Home' && to.name != 'NotFound'
	// 	&& (!MenusInfoKey || MenusInfoKey.indexOf(to.name) === -1)) { //不存在
	// 	console.log('不存在 NotFound');
	// 	next({
	// 		path: '/NotFound'
	// 	})
	// }

	if (to.matched.some(record => record.meta.isWh)) { //是否需要鉴权
		if (!userInfo || !userInfo.id) { //是否登录
			Vue.prototype.$confirm(showTips.noLogin, showTips.title, {
				type: 'warning'
			}).then(() => {
				next({
					path: '/loginFrame/login',
				});
			}).catch(() => {
				next(false);
				//加载进度条结束
				// NProgress.done();
			});
			// next(false);
			//加载进度条结束
			// NProgress.done();
			return;
		}
		if ('1' !== userInfo.isWh) { //是否有仓库权限
			Vue.prototype.$confirm(showTips.noWhAuth, showTips.title, {
				type: 'warning'
			}).then(() => {
				next({
					path: '/loginFrame/auth',
				})
			}).catch(() => {
				// window.location.reload(true);
				next(false);
				//加载进度条结束
				// NProgress.done();
			});
			// NProgress.done();
			return;
		}
		let allMenus = whiteList.concat(MenusInfoKey); //白名单合并菜单项的权限
		if (!allMenus.includes(to.name)) { //是否有菜单权限
			console.log('不存在 NotFound');
			// NProgress.done();
			next({
				path: '/loginFrame/NotFound'
			})
		}
	}
	next();
	// NProgress.done();
	// 权限判断
	// //官方写法
	// if (to.matched.some(record =>
	// 		record.meta.isWh)) {
	// 	if (!userInfo || !userInfo.id) {
	// 		Vue.prototype.$confirm(showTips.noLogin, showTips.title, {
	// 			type: 'warning'
	// 		}).then(() => {
	// 			next({
	// 				path: '/loginFrame/login',
	// 			});
	// 		}).catch(() => {
	// 			next(false);
	// 			//加载进度条结束
	// 			NProgress.done();
	// 		});
	// 		// next(false);
	// 		//加载进度条结束
	// 		NProgress.done();
	// 		return;
	// 	} else {}
	// } else {
	// 	// next();
	// }

	// console.log('权限认证');

	// if (to.matched.some(record => record.meta.isWh && '1' !== userInfo.isWh)) {

	// 	Vue.prototype.$confirm("您还没有互易仓权限，去申请吗？", showTips.title, {
	// 		type: 'warning'
	// 	}).then(() => {
	// 		next({
	// 			path: '/loginFrame/auth',
	// 		})
	// 	}).catch(() => {
	// 		// window.location.reload(true);
	// 		next(false);
	// 		//加载进度条结束
	// 		NProgress.done();
	// 	});

	// } else {
	// 	next() // 确保一定要调用 next()
	// }

})

router.afterEach((to, from) => {
	NProgress.done();
	window.scrollTo(0, 0);

});

export default router